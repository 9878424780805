import React, { useState } from 'react';
import { bool, node, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import * as validators from '../../../util/validators';
import { getPropsForCustomUserFieldInputs } from '../../../util/userHelpers';

import { Form, PrimaryButton, FieldTextInput, CustomExtendedDataField } from '../../../components';

import FieldSelectUserType from '../FieldSelectUserType';
import UserFieldDisplayName from '../UserFieldDisplayName';
import UserFieldPhoneNumber from '../UserFieldPhoneNumber';

import css from './SignupForm.module.css';

// Função para obter um tipo de usuário único, se aplicável
const getSoleUserTypeMaybe = userTypes =>
  Array.isArray(userTypes) && userTypes.length === 1 ? userTypes[0].userType : null;


// Componente de campo CPF

const CPFInput = (fieldProps) => {
  const [cpf, setCpf] = useState('');
  const [erro, setErro] = useState(null);

  const validarCPF = (cpf) => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, '');

    // Verifica se o CPF tem 11 dígitos e não é uma sequência repetida
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return "CPF inválido";
    }

    // Validação do primeiro dígito verificador
    let soma = 0;
    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf[i - 1]) * (11 - i);
    }
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf[9])) {
      return "CPF inválido";
    }

    // Validação do segundo dígito verificador
    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf[i - 1]) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf[10])) {
      return "CPF inválido";
    }

    // CPF válido    
    return undefined;
  };


  const formatarCPF = (cpf) => {
    // Remove qualquer caractere que não seja número
    cpf = cpf.replace(/\D/g, '');

    // Limita o CPF a 11 dígitos
    if (cpf.length > 11) {
      cpf = cpf.slice(0, 11);
    }

    // Aplica a máscara de CPF
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return cpf;
  };


  return (
    <div className={css.customFields}>
      <CustomExtendedDataField
        {...fieldProps}
        maskFunction={formatarCPF}
        validateFunction={validarCPF}
        placeholder="Digite seu CPF."
        type="text"
      />
    </div>
  );
};


// Componente de campo CNPJ
const CNPJInput = (fieldProps) => {
  const [cnpj, setCnpj] = useState('');
  const [erro, setErro] = useState(null);

  const validarCNPJ = (cnpj) => {
    // Remove caracteres não numéricos
    cnpj = cnpj.replace(/\D/g, '');

    // Verifica se o CNPJ tem 14 dígitos e não é uma sequência repetida
    if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) {
      return "CNPJ inválido";
    }

    // Validação do primeiro dígito verificador
    let soma = 0;
    let pesos1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < 12; i++) {
      soma += parseInt(cnpj[i]) * pesos1[i];
    }
    let resto = soma % 11;
    let digito1 = resto < 2 ? 0 : 11 - resto;
    if (digito1 !== parseInt(cnpj[12])) {
      return "CNPJ inválido";
    }

    // Validação do segundo dígito verificador
    soma = 0;
    let pesos2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < 13; i++) {
      soma += parseInt(cnpj[i]) * pesos2[i];
    }
    resto = soma % 11;
    let digito2 = resto < 2 ? 0 : 11 - resto;
    if (digito2 !== parseInt(cnpj[13])) {
      return "CNPJ inválido";
    }

    // CNPJ válido
    return undefined;
  };

  const formatarCNPJ = (cnpj) => {
    // Remove qualquer caractere que não seja número
    cnpj = cnpj.replace(/\D/g, '');

    // Limita o CNPJ a 14 dígitos
    if (cnpj.length > 14) {
      cnpj = cnpj.slice(0, 14);
    }

    // Aplica a máscara de CNPJ
    cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
    cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
    cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
    cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");

    return cnpj;
  };

  return (
    <div className={css.customFields}>
      <CustomExtendedDataField
        {...fieldProps}
        maskFunction={formatarCNPJ}
        validateFunction={validarCNPJ}
        placeholder="Digite seu CNPJ."
        type="text"
      />
    </div>
  );
};


// Componente de campo de IE
const IEInput = (fieldProps) => {
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [erro, setErro] = useState(null);

  const validarIE = (ie) => {
    // Remove caracteres não numéricos
    ie = ie.replace(/\D/g, '');

    // Validação simples de comprimento (8 dígitos, podendo variar entre estados)
    if (ie.length < 3) {
      return "Inscrição Estadual inválida";
    }

    // IE válida
    return undefined;
  };
  

  return (
    <div className={css.customFields}>
      <CustomExtendedDataField
        {...fieldProps}        
        validateFunction={validarIE}
        placeholder="Digite sua Inscrição Estadual."
        type="text"
      />
    </div>
  );
};


const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    initialValues={{ userType: props.preselectedUserType || getSoleUserTypeMaybe(props.userTypes) }}
    render={formRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        termsAndConditions,
        preselectedUserType,
        userTypes,
        userFields,
        values,
      } = formRenderProps;

      const { userType } = values || {};

      // Validação de email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );

      // Validação de senha
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // Campos personalizados do usuário
      const userFieldProps = getPropsForCustomUserFieldInputs(userFields, intl, userType);
      const noUserTypes = !userType && !(userTypes?.length > 0);
      const userTypeConfig = userTypes.find(config => config.userType === userType);
      const showDefaultUserFields = userType || noUserTypes;
      const showCustomUserFields = (userType || noUserTypes) && userFieldProps?.length > 0;

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldSelectUserType
            name="userType"
            userTypes={userTypes}
            hasExistingUserType={!!preselectedUserType}
            intl={intl}
          />

          {showDefaultUserFields ? (
            <div className={css.defaultUserFields}>
              <FieldTextInput
                type="email"
                id={formId ? `${formId}.email` : 'email'}
                name="email"
                autoComplete="email"
                label={intl.formatMessage({
                  id: 'SignupForm.emailLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.emailPlaceholder',
                })}
                validate={validators.composeValidators(emailRequired, emailValid)}
              />
              <div className={css.name}>
                <FieldTextInput
                  className={css.firstNameRoot}
                  type="text"
                  id={formId ? `${formId}.fname` : 'fname'}
                  name="fname"
                  autoComplete="given-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.firstNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.firstNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.firstNameRequired',
                    })
                  )}
                />
                <FieldTextInput
                  className={css.lastNameRoot}
                  type="text"
                  id={formId ? `${formId}.lname` : 'lname'}
                  name="lname"
                  autoComplete="family-name"
                  label={intl.formatMessage({
                    id: 'SignupForm.lastNameLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.lastNamePlaceholder',
                  })}
                  validate={validators.required(
                    intl.formatMessage({
                      id: 'SignupForm.lastNameRequired',
                    })
                  )}
                />
              </div>

              <UserFieldDisplayName
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />

              <FieldTextInput
                className={css.password}
                type="password"
                id={formId ? `${formId}.password` : 'password'}
                name="password"
                autoComplete="new-password"
                label={intl.formatMessage({
                  id: 'SignupForm.passwordLabel',
                })}
                placeholder={intl.formatMessage({
                  id: 'SignupForm.passwordPlaceholder',
                })}
                validate={passwordValidators}
              />

              <UserFieldPhoneNumber
                formName="SignupForm"
                className={css.row}
                userTypeConfig={userTypeConfig}
                intl={intl}
              />

            </div>
          ) : null}


          {/*showCustomUserFields ? (
            <div className={css.customFields}>
              {userFieldProps.map(fieldProps => (
                <CustomExtendedDataField {...fieldProps} formId={formId} />
              ))}
            </div>
          ) : null*/}

          {showCustomUserFields ? (
            <div className={css.customFields}>
              {userFieldProps.map((fieldProps) => {

                // Verifica se é um campo de CPF
                const isCpfField = (fieldProps.name && fieldProps.name.toLowerCase().includes('cpf')) ||
                  (fieldProps.key && fieldProps.key.toLowerCase().includes('cpf')) ||
                  (fieldProps.fieldConfig?.showConfig?.label && fieldProps.fieldConfig.showConfig.label.toLowerCase().includes('cpf'));

                // Verifica se é um campo de CNPJ
                const isCnpjField = (fieldProps.name && fieldProps.name.toLowerCase().includes('cnpj')) ||
                  (fieldProps.key && fieldProps.key.toLowerCase().includes('cnpj')) ||
                  (fieldProps.fieldConfig?.showConfig?.label && fieldProps.fieldConfig.showConfig.label.toLowerCase().includes('cnpj'));

                // Verifica se é um campo de Inscrição Estadual
                const isIEField = (fieldProps.name && fieldProps.name.toLowerCase().includes('inscricaoEstadual')) ||
                  (fieldProps.key && fieldProps.key.toLowerCase().includes('inscricaoEstadual')) ||
                  (fieldProps.fieldConfig?.showConfig?.label && fieldProps.fieldConfig.showConfig.label.toLowerCase().includes('inscrição estadual'));


                const fieldId = `${formId}_${fieldProps.key || fieldProps.name}`;

                return (
                  <div key={fieldId}>
                    {isCpfField ? (
                      <CPFInput {...fieldProps} formId={formId} />
                    ) : isCnpjField ? (
                      <CNPJInput {...fieldProps} formId={formId} />
                    ) : isIEField ? (
                      <IEInput {...fieldProps} formId={formId} />
                    ) : (
                      <CustomExtendedDataField {...fieldProps} formId={formId} />
                    )}
                  </div>
                );
              })}
            </div>
          ) : null}

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  inProgress: false,
  preselectedUserType: null,
};

SignupFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  inProgress: bool,
  termsAndConditions: node.isRequired,
  preselectedUserType: string,
  userTypes: propTypes.userTypes.isRequired,
  userFields: propTypes.listingFields.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
